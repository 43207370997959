import { lessons_array } from "@/packages/admin/schema/calender/LESSONS_ARRAY";

const { required } = require("vuelidate/lib/validators");

export const CREATE_EVENT = {
  /* Form SCHEMA */
  schema: [
    {
      id: "date",
      label: "date",
      placeholder: "",
      multiple: true,
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "date",
    },
    {
      id: "lesson",
      label: "lesson",
      placeholder: "",
      items: [...lessons_array],
      cols: 12,
      value: "value",
      text: "name",
      lg: 4,
      md: 12,
      sm: 12,
      multiple: true,
      show: true,
      type: "select",
    },
    {
      id: "start_time",
      label: "start_time",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "time",
    },
    {
      id: "end_time",
      label: "end_time",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "time",
    },
  ],

  /* Form MODEL */
  model: {
    date: null,
    start_time: null,
    end_time: null,
    lesson: [],
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      date: { required },
      start_time: { required },
      end_time: { required },
      lesson: { required },
    },
  },
};
