<template>
  <div>
    <Calender/>
  </div>
</template>

<script>
import Calender from "@/packages/admin/components/schedule/Calender";
export default {
  name: "schedule",
  components: { Calender }
};
</script>

<style scoped>

</style>