<template>
  <v-dialog width="800px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          event ? $_t("attribute.edit_period") : $_t("attribute.create_period")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-4">
        <div class="text--disabled">
          {{ $_t("attribute.fill_and_create_your_period") }}
        </div>
        <div v-if="event">
          <!--          <span class="pr-3">-->
          <!--            <v-icon>mdi-clock-outline</v-icon>-->
          <!--          </span>-->
          <!--          <span class="pr-3">{{ `${$_date(event.start)}` }}</span>-->
          <!--          <span>{{ `${form.start_time} - ${form.end_time}` }}</span>-->
          <div>
            <span class="pr-3">
              <v-icon>mdi-clock-outline</v-icon>
            </span>
            <span class="pr-3">{{ `${$_date(event.start)}` }}</span>
            <span>{{
              `${getMainTime(event.start, "h:mm a")} - ${getMainTime(
                event.end,
                "h:mm a"
              )}`
            }}</span>
          </div>
        </div>
      </v-card-text>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
<!--              <v-btn-->
<!--                v-if="event && editable"-->
<!--                color="error"-->
<!--                text-->
<!--                @click="cancelEvent"-->
<!--              >-->
<!--                {{ $_t("attribute.cancel_time") }}-->
<!--              </v-btn>-->
<!--              <v-spacer></v-spacer>-->
              <v-btn
                v-if="
                  event &&
                  ['Admin', 'SchedulingAdmin', 'TeamLeader'].includes(role)
                "
                color="error"
                text
                @click="deleteEvent"
              >
                {{ $_t("attribute.delete_time") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                v-if="editable"
                color="primary"
                class="ml-4"
                @click="submit"
              >
                {{
                  event
                    ? $_t("attribute.edit_period")
                    : $_t("attribute.create_period")
                }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card>
  </v-dialog>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { CREATE_EVENT } from "@/packages/admin/schema/calender/CREATE_EVENT";
import { getMainTime } from "@/tools/Utils";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";
import { mapGetters } from "vuex";

export default {
  name: "CreatePeriod",
  components: { MyFormGenerator, MainSoftForm },
  data() {
    return {
      getMainTime,
      schema: CREATE_EVENT.schema,
      form: CREATE_EVENT.model,
      editable: true,
      event_colors,
    };
  },
  validations: {
    ...CREATE_EVENT.validations,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      role: "authentication/role",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close(value);
      },
    },
  },
  watch: {
    event(event) {
      if (event) {
        this.editable = event.editable;
        if (!event.editable) {
          this.schema.forEach((inp) => (inp.disabled = true));
        } else {
          if (typeof event.availableTimeId === "number") {
            this.schema.forEach((inp) => (inp.disabled = false));
          } else if (event.state === "Cancel") {
            this.schema.forEach((inp) => (inp.disabled = true));
            this.editable = false;
          } else {
            this.schema[0].disabled = true;
            this.schema[1].disabled = false;
            this.schema[2].disabled = false;
            this.schema[3].disabled = false;
          }
        }
        this.schema[0].multiple = false;
        this.form.start_time = getMainTime(event.start);
        this.form.end_time = getMainTime(event.end);
        this.form.lesson = event.lesson || null;
        this.form.date = this.$_date(event.start, "ISO");
      } else {
        this.schema[0].multiple = true;
        this.editable = true;
      }
    },
  },
  methods: {
    close(value) {
      this.$emit("close", value);
      this.schema.forEach((inp) => (inp.disabled = false));
      this.clear();
    },
    submit() {
      if (!this.isFormValidate()) return;
      if (this.event) {
        const first = new Date(`${this.form.date} ${this.form.start_time}`);
        const second = new Date(`${this.form.date} ${this.form.end_time}`);
        this.$emit("editEvent", {
          teacherTimeTableId: this.event.teacherTimeTableId || null,
          name: this.event.name,
          start: first.getTime(),
          end: second.getTime(),
          availableTimeId: this.event.availableTimeId,
          color: this.event.color,
          lesson: this.form.lesson,
          timed: true,
        });
      } else {
        for (const date of this.form.date) {
          const first = new Date(`${date} ${this.form.start_time}`);
          const second = new Date(`${date} ${this.form.end_time}`);
          const id = Math.floor(Math.random() * 1000 + Date.now());
          this.$emit("addEvent", {
            name: "test",
            start: first.getTime(),
            end: second.getTime(),
            availableTimeId: id,
            color: this.event_colors.new,
            lesson: this.form.lesson,
            timed: true,
          });
        }
      }

      this.close(false);
    },
    cancelEvent() {
      this.$emit("cancelEvent", {
        availableTimeId: this.event.availableTimeId,
        teacherTimeTableId: this.event.teacherTimeTableId || null,
        teacherPublicKey: this.event.teacherPublicKey,
      });
      this.close(false);
    },
    deleteEvent() {
      this.$emit("deleteEvent", {
        availableTimeId: this.event.availableTimeId,
        teacherTimeTableId: this.event.teacherTimeTableId || null,
      });
      this.close(false);
    },
    clear() {
      this.form.end_time = null;
      this.form.start_time = null;
      this.form.date = null;
      this.form.lesson = null;
      this.$v.$reset();
    },
  },
};
</script>

<style scoped></style>
