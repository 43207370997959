<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="250">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            {{ $_t("attribute.today") }}
          </v-btn>
          <v-btn color="primary" class="px-0 mr-3" @click="prev">
            <v-icon large> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn color="primary" class="px-0 mr-4" @click="next">
            <v-icon large> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ getTitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mx-4" @click="addEventHandle">
            {{ $_t("attribute.add_schedule") }}
          </v-btn>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ $_t(`attribute.${type}`) }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{
                  $_t("attribute.day")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>
                  {{ $_t("attribute.week") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>
                  {{ $_t("attribute.month") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            color="primary"
            :disabled="is_loading"
            outlined
            class="ml-4"
            @click="saveChanges"
          >
            {{ $_t("attribute.save") }}
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <div class="d-flex flex-wrap flex-fill justify-start">
              <v-col cols="6">
                <v-select
                  hide-details
                  outlined
                  :label="$_t('attribute.lessons')"
                  dense
                  :items="lessons"
                  v-model="default_lesson"
                  multiple
                  item-text="name"
                  item-value="value"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  hide-details
                  outlined
                  :label="$_t('attribute.filterLessons')"
                  dense
                  :items="lessons"
                  v-model="filteredLessons"
                  @change="fetchEvents"
                  multiple
                  clearable
                  item-text="name"
                  item-value="value"
                ></v-select>
              </v-col>
              <!--              selected teacher-->
              <v-col
                cols="6"
                v-if="['Admin', 'TeamLeader', 'SchedulingAdmin'].includes(role)"
              >
                <v-select
                  hide-details
                  outlined
                  :label="$_t('attribute.selectedTeacher')"
                  dense
                  :items="teachers"
                  v-model="teacherPublicKey"
                  @input="getClassroomsTimes"
                  clearable
                  :item-text="
                    (item) =>
                      `${item.fullName} | ${
                        item.nickName
                      }| ${item.state} | ${item.baseType.map((t) => $_t(`attribute.${t}`))}`
                  "
                  item-value="publicKey"
                >
                  <template v-slot:prepend-item>
                    <div class="px-3 py-2">
                      <v-text-field
                        outlined
                        :label="$_t('attribute.searchTeacher')"
                        dense
                        hide-details
                        @input="fetchTeachers"
                        v-model="search"
                      />
                    </div>
                  </template>
                </v-select>
              </v-col>
              <!--            filter by teachers -->
              <v-col
                cols="6"
                v-if="['Admin', 'TeamLeader', 'SchedulingAdmin'].includes(role)"
              >
                <v-select
                  hide-details
                  outlined
                  :label="$_t('attribute.filterTeachers')"
                  dense
                  multiple
                  :items="teachers"
                  v-model="teacherPublicKeys"
                  @change="fetchEvents"
                  clearable
                  :item-text="
                    (item) =>
                      `${item.fullName} | ${
                        item.nickName
                      }| ${item.state} | ${item.baseType.map((t) => $_t(`attribute.${t}`))}`
                  "
                  item-value="publicKey"
                >
                  <template v-slot:prepend-item>
                    <div class="px-3 py-2">
                      <v-text-field
                        outlined
                        :label="$_t('attribute.searchTeacher')"
                        dense
                        hide-details
                        @input="fetchTeachers"
                        v-model="search"
                      />
                    </div>
                  </template>
                </v-select>
              </v-col>
            </div>
            <div class="d-flex flex-wrap flex-fill justify-start">
              <EventsStateColor />
              <v-col cols="12" md="3">
                <v-select
                  hide-details
                  outlined
                  label="Times state"
                  dense
                  :items="classStates"
                  v-model="timeState"
                  clearable
                  @input="getClassroomsTimes"
                  item-text="text"
                  item-value="value"
                >
                </v-select>
              </v-col>
            </div>
          </v-row>
        </v-container>
      </v-sheet>
      <v-sheet height="700">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="mixEvents"
          :locale="$i18n.locale"
          :event-color="getEventColor"
          :type="type"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
        >
          <template v-slot:day-header="{ date }">
            <!--             v-if="date === $_date(new Date(), 'ISO')"-->
            <div class="d-flex">
              <v-tooltip color="primary" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-auto"
                    v-on="on"
                    v-bind="attrs"
                    fab
                    dark
                    outlined
                    small
                    color="primary"
                    @click="repeatEvents(date)"
                  >
                    <v-icon>mdi-content-duplicate</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  `${$_t("attribute.repeated_for")} ${repeatedDays} ${$_t(
                    "attribute.days"
                  )}`
                }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:event="{ event, timed, eventSummary }">
            <div @click="showEvent({ event })" style="height: 90%">
              <div class="v-event-draggable">
                {{
                  `${
                    event?.lesson.map((l) => `${$_t(`attribute.${l}`)}`) ||
                    event?.baseType.map((l) => `${$_t(`attribute.${l}`)}`)
                  }`
                }}
                <br />
                {{ typeof event.availableTimeId === "number" ? "New" : "" }}
                <div v-if="event.teacherName">
                  {{ `${$_t("attribute.teacher")}: ${event.teacherName}` }}
                </div>
              </div>
              <div class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>
              <!--              <v-icon @click="showEvent({event})">mdi-clock</v-icon>-->
            </div>
            <div
              v-if="timed && typeof event.availableTimeId === 'number'"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            ></div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <!--    create period -->
    <CreatePeriod
      @close="close"
      @addEvent="addEvent"
      @editEvent="editEvent"
      @cancelEvent="cancelEvent"
      @deleteEvent="deleteTime"
      :event="selectedEvent"
      :is-show-modal="isShowCreatePeriod"
    />
  </v-row>
</template>
<script>
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { _t, getMainTime } from "@/tools/Utils";
import { lessons_array } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import CreatePeriod from "@/packages/admin/components/schedule/CreatePeriod";
import message from "@/tools/Message";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import { mapGetters } from "vuex";
import EventsStateColor from "@/packages/admin/components/schedule/EventsStateColor";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";
import { classStates } from "@/packages/admin/schema/CLASS_STATES";

export default {
  name: "Calender",
  components: { EventsStateColor, CreatePeriod, MyFormGenerator },
  computed: {
    getTitle() {
      return this.$refs.calendar.title;
    },
    ...mapGetters({
      allEvents: "class/getTeacherEvents",
      user: "authentication/user",
      role: "authentication/role",
      teachers: "admin/get_teachers",
      is_loading: "loading/is_loading",
      classTimes: "class/classTimes",
    }),
    mixEvents() {
      return [...this.events, ...this.allEvents];
    },
  },
  data() {
    return {
      getMainTime,
      isShowCreatePeriod: false,
      lessons: lessons_array,
      filteredLessons: [],
      default_lesson: [],
      repeatedDays: 6,
      focus: "",
      type: "week",
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
      events: [],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      teacherPublicKey: null,
      teacherPublicKeys: [],
      event_colors,
      timeState: null,
      classStates,
      search: "",
      start: null,
      end: null,
    };
  },
  async mounted() {
    this.$refs.calendar.checkChange();
    if (["Admin", "SchedulingAdmin", "TeamLeader"].includes(this.user.role)) {
      await this.fetchTeachers();
    }
  },
  methods: {
    async fetchTeachers() {
      await this.$actions.getUsers({
        all: true,
        role: "Teacher",
        search: this.search,
        dispatch: "admin/setTeachers",
      });
    },
    async fetchEvents() {
      const { events } = await this.$actions.getTeacherTimetableFiltered({
        lessonNames: this.filteredLessons,
        teacherPublicKey: this.teacherPublicKeys,
        dispatch: "class/setTeacherEvents",
        start: this.start,
        end: this.end,
      });
      console.log(events, "events");
    },
    async getClassroomsTimes() {
      // this.events = [];
      await this.$actions.getClassroomsTimes({
        teacherPublicKey:
          this.role === "Teacher" ? this.user.publicKey : this.teacherPublicKey,
        timeState: this.timeState,
        start: this.start,
        end: this.end,
      });
    },
    repeatEvents(today) {
      let today_events = this.events.filter(
        (event) =>
          today === this.$_date(event.start, "ISO") &&
          typeof event.availableTimeId === "number"
      );
      if (!today_events.length)
        return message.show(this.$_t("attribute.period_is_empty"));
      for (let i = 1; i <= this.repeatedDays; i++) {
        let addedTime = today_events.map((event) => {
          return {
            ...event,
            start: event.start + i * 24 * 60 * 60 * 1000,
            end: event.end + i * 24 * 60 * 60 * 1000,
            availableTimeId: Math.floor(Math.random() * 100000 + Date.now()),
          };
        });
        this.events = this.events.concat([...addedTime]);
      }
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      if (!this.default_lesson.length)
        return message.error("Please select lesson");
      if (
        !this.teacherPublicKey &&
        ["Admin", "SchedulingAdmin", "TeamLeader"].includes(this.user.role)
      )
        return message.error("Please first select a teacher");

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          color: this.event_colors.new,
          start: this.createStart,
          end: this.createStart,
          availableTimeId: Math.floor(Math.random() * 1000 + Date.now()),
          lesson: this.default_lesson,
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        if (typeof this.dragEvent.availableTimeId === "string") return;
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        if (typeof this.createEvent.availableTimeId === "string") return;
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    close() {
      this.isShowCreatePeriod = false;
      this.selectedEvent = null;
    },
    addEvent(event) {
      this.events.push({ ...event });
    },
    showEvent({ event }) {
      console.log(event);
      // console.log(this.classTimes);
      // console.log(
      //   this.classTimes.find(
      //     (c) =>
      //       c.availableTimeId === event.availableTimeId &&
      //       c.start === event.start &&
      //       c.end === event.end
      //   )
      // );
      // if event was a session do not open modal;
      if (
        this.classTimes.find(
          (c) =>
            c.availableTimeId === event.availableTimeId &&
            c.start === event.start &&
            c.end === event.end
        )
      )
        return;
      this.selectedEvent = {
        ...event,
        editable:
          typeof event.availableTimeId === "number"
            ? true
            : event.hasClassroom
            ? false
            : ["Admin", "SchedulingAdmin", "TeamLeader"].includes(
                this.user.role
              ),
      };
      this.isShowCreatePeriod = true;
    },
    async cancelEvent({
      availableTimeId,
      teacherTimeTableId,
      teacherPublicKey,
    }) {
      if (typeof availableTimeId === "number")
        this.events = this.events.filter(
          (event) => event.availableTimeId !== availableTimeId
        );
      else {
        await this.$actions.cancelTime({
          availableTimeId,
          teacherTimeTableId,
          teacherPublicKey,
        });
        await this.fetchEvents({});
      }
    },
    async deleteTime({ availableTimeId, teacherTimeTableId }) {
      if (typeof availableTimeId === "number")
        this.events = this.events.filter(
          (event) => event.availableTimeId !== availableTimeId
        );
      else {
        await this.$actions.removeTime({
          availableTimeId,
          teacherTimeTableId,
        });
        await this.fetchEvents({});
      }
    },
    async editEvent(editedEvent) {
      if (typeof editedEvent.availableTimeId === "number") {
        this.events = this.events.map((event) =>
          editedEvent.availableTimeId === event.availableTimeId
            ? editedEvent
            : event
        );
      } else {
        await this.$actions.editTimetable({
          teacherTimeTableId: editedEvent.teacherTimeTableId,
          availableTimeId: editedEvent.availableTimeId,
          lessonName: editedEvent.lesson,
          start: editedEvent.start / 1000,
          end: editedEvent.end / 1000,
        });
        await this.fetchEvents({});
      }
    },
    saveChanges() {
      console.log(this.events);
      if (!this.events.length)
        return message.error("Please select some available time");
      if (
        !this.teacherPublicKey &&
        ["Admin", "SchedulingAdmin", "TeamLeader"].includes(this.user.role)
      )
        return message.error("please select a teacher");
      let requests = [];
      const lessons = this.events.map((e) => e.lesson);
      console.log(lessons);
      for (const lesson of lessons) {
        let events = this.events.filter(
          (e) => e.lesson === lesson && typeof e.availableTimeId === "number"
        );
        const isLessonRepeated = requests.find((r) => r.lessonName === lesson);
        console.log(isLessonRepeated, "is lesson repeated");
        if (isLessonRepeated) continue;
        if (!events.length) continue;

        let data = {
          lessonName: lesson,
          availableTimes: events.map((e) => {
            return { start: e.start / 1000, end: e.end / 1000 };
          }),
        };
        if (
          ["Admin", "SchedulingAdmin", "TeamLeader"].includes(this.user.role)
        ) {
          data.teacherPublicKey = this.teacherPublicKey;
        }
        requests.push(data);
      }

      console.log(requests, "requests");
      if (!requests.length) return message.error("please create an event");
      Promise.all(
        requests.map((data) =>
          api.post(
            urls.api(
              `create_${
                ["Admin", "SchedulingAdmin", "TeamLeader"].includes(
                  this.user.role
                )
                  ? "admin"
                  : "teacher"
              }_timetable`
            ),
            data
          )
        )
      )
        .then((data) => {
          console.log("Res", data);
          this.fetchEvents({});
          message.success(_t("CREATE_SUCCESS"));
        })
        .catch((err) => {
          console.log("error", err);
          message.error(err.response.data.Message);
        })
        .finally(() => {
          this.events = [];
        });
    },
    async updateRange({ start, end }) {
      console.log({ start, end });
      const viewStart = this.$refs.calendar.getStartOfWeek(start);
      const startDate = new Date(viewStart.date).getTime();

      let viewEnd = this.$refs.calendar.getEndOfWeek(end);
      const endDate = new Date(`${viewEnd.date}T23:59:59`).getTime();
      this.start = startDate / 1000;
      this.end = endDate / 1000;

      await this.fetchEvents({});
      await this.getClassroomsTimes();
      // const events = [];
      //
      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      // const days = (max.getTime() - min.getTime()) / 86400000;
      // const eventCount = this.rnd(days, days + 20);
      //
      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = this.rnd(0, 3) === 0;
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      //   const second = new Date(first.getTime() + secondTimestamp);
      //   // const id = randomUUID();
      //   const id = Math.floor(Math.random() * 1000 + Date.now());
      //   events.push({
      //     name: this.names[this.rnd(0, this.names.length - 1)],
      //     start: first,
      //     end: second,
      //     id,
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //     timed: !allDay,
      //   });
      // }
      //
      // this.events = [...events, this.events];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    addEventHandle() {
      // if (!this.teacherPublicKey && this.user.role === "Admin")
      //   return message.error("please select a teacher");
      this.isShowCreatePeriod = true;
    },
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
